<template>
  <div>
    <div v-if="isLoading">
      <base-skeleton-loader type="dashboard" :count="1"></base-skeleton-loader>
    </div>
    <div class="card profile" v-else>
      <div class="profile__image" v-if="user.avatar">
        <img
          v-lazy="`${$config.IMG_HOST}/160x160/${user.avatar}`"
          alt=""
          class="img-fluid"
        />
      </div>
      <div class="profile__title">
        {{ user.first_name }} {{ user.last_name }}
      </div>
      <p class="profile__subTitle">
        {{ `${user.type == 0 ? "influencer" : "brand"}` }}
      </p>
      <p class="profile__subTitle" v-if="user.type == 0">
        {{$t('global.followers')}}: {{ `${user.followers == 0 ? 0 : user.followers}` }}
      </p>
      <div class="button-row">
        <router-link
          :to="user.type == 0 ? $i18nRoute({name: 'userEditProfile'}) : $i18nRoute({name: 'brandEditProfile'})"
          class="btn btn-primary small"
          >{{$t('global.edit_profile')}}</router-link
        >
      </div>
      <!-- Base Select -->
        <base-multi-select
          :list="languages"
          label="name"
          track-by="id"
          v-model="selectedLanguage"
          @input = "changeLanguage"
          value="1"
          class='filter-tags'
          :multiple="false"
        ></base-multi-select>
      <!-- Base Select -->
      <!-- <select
        class="LanguageSwitcher"
        name="language"
        @change="changeLanguage"
      >
        <option
          v-for="lang in supportedLanguages"
          :key="lang"
          :selected="isCurrentLanguage(lang)"
          :class="{ 'is-selected': isCurrentLanguage(lang) }"
          :value="lang"
        >
          {{lang}}
        </option>
      </select> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Trans } from '@/plugins/Translation'

export default {
  data() {
    return {
      isLoading: true,
      selectedLanguage: {
        id: "",
        name: "",
        iso_code: "",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  },
  computed: {
    ...mapGetters(["user", "languages"]),
    supportedLanguages () {
      return Trans.supportedLanguages
    },
    currentLanguage () {
      return Trans.currentLanguage
    }
  },
  created() {
    for (let i = 0; i < this.languages.length; i++) {
      if (this.isCurrentLanguage(this.languages[i].iso_code)) {
        this.selectedLanguage = this.languages[i];
      }
    }
  },
  methods: {
    changeLanguage (e) {
      const lang = e !== null ? e.iso_code : this.$route.params.lang;
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    },
    isCurrentLanguage (lang) {
      return lang === this.currentLanguage
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  padding: rem(24px) rem(16px) rem(16px) rem(16px);
  border-radius: 7px !important;
  text-align: center;
  overflow: visible;
  &__image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    margin: auto;
    img {
      //   border-radius: 50%;
      //   overflow: hidden;
      width: 100%;
      height: 100%;
    }
    margin-bottom: rem(14px);
  }
  &__title {
    font-size: rem(18px);
    color: var(--textPrimary);
    font-weight: 700;
    margin-bottom: rem(4px);
  }
  &__subTitle {
    font-size: rem(16px);
    font-weight: 300;
    margin-bottom: rem(12px);
  }
  .button-row {
    margin: 0;
    .btn.btn-primary {
      font-size: rem(14px);
      padding-right: rem(24px);
      padding-left: rem(24px);
    }
  }
  .profile__image {
    cursor: pointer;
  }
}
.LanguageSwitcher {
  margin-top: 20px;
}
.filter-tags {
  border: 1px solid #CCD4E0;
  margin-top: 20px;
  border-radius: 8px !important;
  .multiselect{
    &__content {
        max-height: 400px;
        overflow: auto;
    }
  }
}
</style>
